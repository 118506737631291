import { AxiosPromise } from 'axios';
import UserBaseInfo from '@/model/user/UserBaseInfo';
import UserID from '@/model/user/UserID';
import Accesskey from '@/model/accessKeys/Accesskey';
import Email from '@/model/user/Email';
import PasswordChangeInfo from '@/model/user/PasswordChangeInfo';
import axios from '../AxiosBase';

export default {
  /**
   * ユーザを新規登録
   */
  add(userBaseInfo: UserBaseInfo):AxiosPromise<any> {
    return axios.post('registerapi/add', userBaseInfo);
  },

  /**
   * 認証
   */
  activate(accesskey: Accesskey, userid: UserID):AxiosPromise<any> {
    return axios.post('registerapi/activate', { accesskey, userid });
  },

  /**
   * 登録済みメールからユーザ情報を取得しアクセスキーを再発行
   */
  reissue(email: Email):AxiosPromise<any> {
    return axios.post('registerapi/reissue', email);
  },

  /**
   * パスワード再設定時の認証確認
   */
  reissueActivate(accesskey: Accesskey, userid: UserID):AxiosPromise<any> {
    return axios.post('registerapi/reissueActivate', { accesskey, userid });
  },

  /**
   * パスワード再設定
   */
  passwordUpdate(passwordInfo: PasswordChangeInfo):AxiosPromise<any> {
    return axios.post('registerapi/passwordUpdate', passwordInfo);
  },
};
