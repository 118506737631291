
import { Vue, Component } from 'vue-property-decorator';
import { Route } from 'vue-router';
import PasswordMeter from '@/components/Parts/PasswordMeter.vue';
import Password from '@/model/user/Password';
import Accesskey from '@/model/accessKeys/Accesskey';
import UserID from '@/model/user/UserID';
import UserRegistApi from '@/module/api/UserRegistApi';
import PasswordChangeInfo from '@/model/user/PasswordChangeInfo';

Component.registerHooks([
  'beforeRouteEnter'
]);
@Component({
  components: {
    PasswordMeter
  }
})
export default class PasswordReset extends Vue {
  passwordInfo: PasswordChangeInfo = new PasswordChangeInfo();
  accessKey: Accesskey = new Accesskey();
  confirmPassword: Password = new Password();
  displayFlg: boolean = false;
  passwordRegistFlg: boolean = false;
  setMessage = '';

  beforeRouteEnter(to: Route, from: Route, next: any): void {
    next((vm: PasswordReset) => vm.initialize(to));
  }
  initialize(to: Route): void {
    this.accessKey = new Accesskey(to.params.accessKey);
    this.passwordInfo.userId = new UserID(to.params.userId);
  }

  /**
   * 初期表示処理
   */
  mounted():void{
    UserRegistApi.reissueActivate(this.accessKey, this.passwordInfo.userId)
      .then(() => {
        this.displayFlg = true;
      }).catch(() => {
        this.displayFlg = false;
        this.setMessage = 'アクセスキーが正しくないか、有効期限切れです。';
      });
  }

  /**
   * 再設定ボタン押下
   */
  resetting(): void {
    UserRegistApi.passwordUpdate(this.passwordInfo).then(() => {
      this.passwordRegistFlg = true;
    });
  }

  /**
   * 戻るボタン押下
   */
  back(): void {
    this.$router.push('/login.html');
  }

  /**
   * 再設定ボタン活性判定
   */
  isValid(): boolean {
    // パスワード
    if (this.passwordInfo.newPassword.value.length < 6 || this.passwordInfo.newPassword.value.length > 36) return true;
    const reg = new RegExp(/^[a-zA-Z0-9!-/:-@¥[-`{-~]*$/);
    const res = reg.test(this.passwordInfo.newPassword.value);
    if (!res) return true;
    if (this.passwordInfo.newPassword.value !== this.confirmPassword.value) return true;

    return false;
  }
}
