import Password from './Password';
import UserID from './UserID';

/**
 * パスワード情報
 */
export default class PasswordChangeInfo {
  userId: UserID;
  newPassword: Password;
  currentPassword: Password;

  constructor(
    userId?: UserID,
    newPassword?: Password,
    currentPassword?: Password
  ) {
    this.userId = (userId === undefined ? new UserID() : userId);
    this.newPassword = (newPassword === undefined ? new Password() : newPassword);
    this.currentPassword = (currentPassword === undefined ? new Password() : currentPassword);
  }
}
